@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONTS DEFINITION */
@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Ultralight-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Ultralight-WebS.woff") format("woff");
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Thin-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Thin-WebS.woff") format("woff");
  font-weight: 275;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Light-WebS.woff2") format("woff2"),
    F url("../../public/fonts/SuisseIntl-Light-WebS.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Regular-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Regular-WebS.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Book-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Book-WebS.woff") format("woff");
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Medium-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Medium-WebS.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-SemiBold-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-SemiBold-WebS.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Bold-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Bold-WebS.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src:
    url("../../public/fonts/SuisseIntl-Black-WebS.woff2") format("woff2"),
    url("../../public/fonts/SuisseIntl-Black-WebS.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../public/fonts/RobotoMono-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../public/fonts/RobotoMono-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../public/fonts/RobotoMono-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*SQLite Cloud*/

.sqlitecloud-link {
  color: #2175e2;
}

.unstyled-link {
  color: inherit;
  text-decoration: none;
}

.unstyled-link:hover .unstyled-link:active {
  text-decoration: none;
}

/* .firstProjectMap {
  position: relative;
} */

.firstProjectMap {
  background-image: url("/static/img/sqlitecloud/cloud-map-clean-15-opac.png");
  background-repeat: no-repeat;
  background-position: center 70px;
  background-size: contain;
}

/* PERFET SCROLL BAR */
.ps__rail-x {
  z-index: 1199 !important;
}

.ps__rail-y {
  z-index: 1199 !important;
}

/*LOGS*/

/* width */
.logs-hor-scrollbar::-webkit-scrollbar {
  height: 6px;
}

/* Track */
.logs-hor-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.logs-hor-scrollbar::-webkit-scrollbar-thumb {
  background: #bbb;
}

/* Handle on hover */
.logs-hor-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

/*MONACO EDITOR*/

.sqlitecloud-editor section {
  position: relative;
  padding-top: 0.5rem;
}

.theme-dark.sqlitecloud-editor section {
  background-color: #0c0c0c;
}

.theme-light.sqlitecloud-editor section {
  background-color: #f5f5f6;
}

.monaco-editor .monaco-hover {
  max-width: 100%;
  max-height: 100%;
  /* overflow: auto; */
  white-space: normal;
}

.monaco-editor .current-line {
  border: 0px transparent solid !important;
}

.sqlitecloud-editor-read-only .monaco-editor-overlaymessage {
  display: none !important;
}

.sqlitecloud-editor-rounded section {
  position: relative;
  padding-top: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.theme-dark.sqlitecloud-editor-rounded section {
  border-top: #5a5877 solid 1px;
  background-color: #0c0c0c;
}

.theme-light.sqlitecloud-editor-rounded section {
  background-color: #f5f5f6;
}

.sqlitecloud-editor-rounded .monaco-editor {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.sqlitecloud-editor-rounded .monaco-editor .overflow-guard {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.sqlitecloud-editor-rounded .monaco-editor .current-line {
  border: 0px transparent solid !important;
}

/* SQLiteCloud TABLE */
.sqlitecloud-table {
  /** table container must be sized by hosting parent */
  width: 100%;
  height: 100%;

  /** determine how editor and panel are arranged */
  display: flex;
  flex-direction: column;
}

/** top panel used to ask for confirmations */
.sqlitecloud-table .table-panel {
  box-sizing: content-box;
  height: 1rem;
  min-height: 1rem;
  padding: 0.7rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sqlitecloud-table .table-button {
  min-width: 64px;
  margin-right: 0.5rem;
  box-sizing: content-box;
  font-size: 12px-reg;
  font-weight: demi;
  font-family: Roboto Mono;
  color: black;
  background: #cfe2f8;
  border: none;
  border-radius: 0.5rem;
  padding: 0.2rem 0.7rem !important;
  cursor: pointer;
}

.sqlitecloud-table .table-button:hover {
  background: #9ac0f1;
}

.sqlitecloud-table .table-button:active {
  background: #cfe2f8;
  border: solid #6ba2ea 1px;
}

/* NODE MAP */

.jvectormap-zoomin {
  display: none;
}

.jvectormap-zoomout {
  display: none;
}

/* SWAGGER */

.swagger-ui .wrapper {
  padding: 0 !important;
  margin: 1rem 0 0 0 !important;
  max-width: unset !important;
}

.swagger-ui .information-container.wrapper {
  display: none;
}

.swagger-ui .scheme-container {
  display: none;
}

.swagger-ui .authorization__btn {
  display: none;
}

.swagger-ui .opblock-summary button {
  padding-right: 0.5rem !important;
}
